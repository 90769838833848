<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">{{ $t('seller.breadcrumb.seller') }}</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ name: 'marketingtools' }">{{ $t('seller.breadcrumb.marketPlatform') }}</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ name: 'couponList', query: { type: couponInfo.type } }">{{ $t('seller.breadcrumb.coupon') }}</el-breadcrumb-item>
				<el-breadcrumb-item>{{ $t('seller.breadcrumb.couponInfo') }}</el-breadcrumb-item>
			</el-breadcrumb>
		</el-card>
		<el-form :model="couponForm" :rules="couponRules" ref="couponForm" size="small" label-width="260px">
			<el-card class="seller-card">
				<template slot="header">{{ $t('seller.coupon.infoTitle') }}</template>
				<el-form-item :label="$t('seller.formItem.name') + ':'">{{ couponInfo.name }}</el-form-item>
				<el-form-item :label="$t('seller.formItem.useTimeMode') + ':'">{{ $t('seller.coupon.fixedUseTime') }}</el-form-item>
				<el-form-item :label="$t('seller.formItem.useTime') + ':'">
					{{ couponInfo.use_begin_time | parseTime('{y}-{m}-{d}') }}{{ $t('seller.unit.to') }}{{ couponInfo.use_end_time | parseTime('{y}-{m}-{d}') }}
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.activityTarget') + ':'">{{ couponInfo.activity_target | targetFilter($t('seller.coupon.activityTargetOption')) }}</el-form-item>
				<el-form-item
					:label="$t('seller.formItem.cheapRemind') + ':'">{{ $t('seller.coupon.paneText2-1') }}{{ couponInfo.price_remind }}{{ $t('seller.coupon.paneText2-2') }}</el-form-item>
				<el-form-item v-if="couponInfo.type == 2" :label="$t('seller.formItem.goodsRange') + ':'" prop="goods_ids">
					<el-dialog :title="$t('seller.dialogTitle.goods')" width="9600px"
						:visible.sync="dialogVisibleGoods" :before-close="handleCloseGoods" custom-class="seller-dialog">
						<el-tabs v-model="activeName" type="card" @tab-click="handleClickGoods">
							<el-tab-pane v-for="(item, index) in dialogGoodsNav" :key="index"
								:label="item.label" :name="item.value"></el-tab-pane>
						</el-tabs>
						<el-scrollbar class="seller-dialog-scrollbar">
							<el-form v-if="activeName == 0" size="mini" :inline="true">
								<el-form-item>
									<el-select v-model="screenForm.store_cate_id" @change="handleSeach">
										<el-option v-for="(item, index) in shopCateOption" :key="index"
											:label="item.label" :value="item.value"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item>
									<el-input v-model="screenForm.goods_name"
										:placeholder="$t('seller.placeholder.babyName')">
									</el-input>
								</el-form-item>
								<el-form-item>
									<el-input v-model="screenForm.goods_code"
										:placeholder="$t('seller.placeholder.babyCode')">
									</el-input>
								</el-form-item>
								<el-form-item>
									<el-input v-model="screenForm.goods_id"
										:placeholder="$t('seller.placeholder.babyId')">
									</el-input>
								</el-form-item>
								<el-form-item>
									<el-button type="primary"
										@click="handleSeach">{{ $t('seller.actions.search') }}</el-button>
								</el-form-item>
							</el-form>
							<el-table v-loading="listLoading" ref="goodsTable" :data="goodsList" style="width: 100%;"
								:row-key="getRowKey" @selection-change="handleSelectionChange" class="seller-table">
								<el-table-column type="selection" :reserve-selection="true"
									:selectable="onSelectable" width="45">
								</el-table-column>
								<el-table-column :label="$t('seller.tableHead.babyDescribe')"
									min-width="220px">
									<template slot-scope="scope">
										<div class="goods-item">
											<img class="pic" :src="scope.row.default_image" alt="">
											<div class="item-mian">
												<div class="title">{{ scope.row.goods_name }}</div>
												<div class="number">
													{{ $t('seller.coupon.goodsCode') + ':' }}{{ scope.row.goods_code ? scope.row.goods_code : '-' }}
												</div>
											</div>
										</div>
									</template>
								</el-table-column>
								<el-table-column :label="$t('seller.tableHead.unitPrice')">
									<template slot-scope="scope">￥{{ scope.row.price }}</template>
								</el-table-column>
								<el-table-column :label="$t('seller.tableHead.stock')" prop="stock"
									align="center"></el-table-column>
								<el-table-column
									:label="'30' + $t('seller.unit.day') + $t('seller.tableHead.totalSales')"
									prop="sales" align="center">
									<template slot-scope="scope">{{ scope.row.sales || 0 }}</template>
								</el-table-column>
							</el-table>
							<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr"
								:limit.sync="pageSize" @pagination="getGoodsList"></pagination>
						</el-scrollbar>
						<div slot="footer">
							<el-button size="small"
								@click="handleCloseGoods">{{ $t('seller.actions.cancel') }}</el-button>
							<el-button type="primary" size="small"
								@click="handleToggleSelecting">{{ $t('seller.actions.confirm') }}</el-button>
						</div>
					</el-dialog>
					<el-button type="primary"
						@click="handleGoodsShow">{{ $t('seller.actions.selectGoods') }}</el-button>
					<span>（{{ $t('seller.actions.selected') }}{{ couponForm.goods_ids.length }}{{ $t('seller.unit.pcs') }}）</span>
				</el-form-item>
				<el-form-item
					:label="$t('seller.formItem.denomination') + ':'">{{ couponInfo.money }}{{ $t('seller.unit.yuan') }}</el-form-item>
				<el-form-item
					:label="$t('seller.formItem.preferentialWay') + ':'">{{ $t('seller.unit.full') }}{{ couponInfo.quota }}{{ $t('seller.unit.yuan') + $t('seller.unit.use') }}</el-form-item>
				<template v-if="$route.params.type === 'edit'">
					<el-form-item :label="$t('seller.formItem.circulation') + ':'" prop="give_num">
						<el-input class="w240" v-model="couponForm.give_num"
							oninput="value=value.replace(/[^\d]/g,'')"></el-input>
						<span class="ml10">{{ $t('seller.unit.sheet') }}</span>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.limitPerPerson') + ':'" prop="limit_give_num">
						<el-select v-model="couponForm.limit_give_num">
							<el-option v-for="item in 5" :key="item" :label="item" :value="item"></el-option>
							<el-option :label="$t('seller.coupon.unlimited')" :value="0"></el-option>
						</el-select>
						<span class="ml10">{{ $t('seller.unit.sheet') }}</span>
					</el-form-item>
				</template>
				<template v-else>
					<el-form-item :label="$t('seller.formItem.circulation') + ':'">
						{{ couponForm.give_num }}{{ $t('seller.unit.sheet') }}
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.limitPerPerson') + ':'">
						{{ couponForm.limit_give_num > 0 ? couponForm.limit_give_num + $t('seller.unit.sheet') : $t('seller.coupon.unlimited') }}
					</el-form-item>
				</template>
			</el-card>
		</el-form>
		<div class="page-bottom">
			<el-button @click="$router.go(-1)">{{ $t('seller.actions.backList') }}</el-button>
			<el-button v-if="$route.params.type === 'edit'" type="primary" @click="handleSave">{{ $t('seller.actions.save') }}</el-button>
		</div>
	</el-scrollbar>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { fetchCoupon, getCouponGoods, updateCoupon } from '@/api/seller/coupon';
export default {
	components: { Pagination },
	filters: {
		targetFilter(type, targetMap) {
			let option = []
			targetMap.forEach(item => {
				option.push(item.label)
			})
			return option[type]
		}
	},
	data() {
		var validateGiveNum = (rule, value, callback) => {
			if (value === '') {
				callback(new Error(this.$t('seller.validator.circulation')));
			} else if (this.couponInfo.channel_type === 0 && parseInt(value) < 1000) {
				callback(new Error(this.$t('seller.validator.least1000')));
			} else if (this.couponInfo.channel_type === 1 && parseInt(value) < 1) {
				callback(new Error(this.$t('seller.validator.least1')));
			} else if (parseInt(value) > 100000) {
				callback(new Error(this.$t('seller.validator.most1w')));
			} else if (parseInt(value) < parseInt(this.couponInfo.give_num)) {
				callback(new Error(this.$t('seller.validator.circulationIncrease')));
			} else {
				callback();
			}
		}
		return {
			datetimes: '',
			couponInfo: {},
			couponForm: {
				coupon_id: 0,
				goods_ids: [],
				give_num: '',
				limit_give_num: '',
			},
			couponRules: {
				goods_ids: [{
					required: true,
					message: this.$t('seller.validator.selectGoods'),
					trigger: 'blur'
				},],
				give_num: [{
					required: true,
					validator: validateGiveNum,
					trigger: 'blur'
				},],
				limit_give_num: [{
					required: true,
					message: this.$t('seller.validator.circulation'),
					trigger: 'blur'
				},]
			},
			dialogVisibleGoods: false,
			activeName: '0',
			dialogGoodsNav: this.$t('seller.coupon.dialogGoodsNav'),
			shopCateOption: this.$t('seller.coupon.shopCateOption'),
			listLoading: false,
			pageCurr: 1,
			pageSize: 5,
			pageTotal: 0,
			goodsList: [],
			checkGoods: [],
			multipleSelection: [],
			screenForm: {
				goods_code: '',
				goods_id: '',
				goods_name: '',
				store_cate_id: '0',
			},
		}
	},
	created() {
		this.getCouponInfo();
		this.getGoodsList();
	},
	methods: {
		getCouponInfo() {
			let loading = this.$loading();
			fetchCoupon({ coupon_id: this.$route.params.id }).then(response => {
				const { data } = response;
				this.couponInfo = data;
				if (data.goods && data.goods.length > 0) {
					let goods_ids = [];
					data.goods.forEach(item => {
						goods_ids.push(item.goods_id);
					})
					this.couponInfo.goods_ids = goods_ids;
					this.couponForm.goods_ids = goods_ids;
				}
				this.couponForm.coupon_id = data.coupon_id;
				this.couponForm.give_num = data.give_num;
				this.couponForm.limit_give_num = data.limit_give_num;
				loading.close();
			}).catch(() => {
				loading.close();
				this.$router.push({ name: 'couponList' });
			})
		},
		getGoodsList() {
			this.listLoading = true
			let param = {
				...this.screenForm,
				type: this.activeName,
				coupon_id: this.couponInfo.coupon_id,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			};
			getCouponGoods(param).then(response => {
				this.goodsList = response.data.list;
				this.pageTotal = parseInt(response.data.total);
				this.listLoading = false
			})
		},
		handleSizeChange(val) {
			this.pageSize = val;
		},
		handleCurrentChange(val) {
			this.pageCurr = val;
			this.getGoodsList();
		},
		handleSeach() {
			this.pageCurr = 1;
			this.getGoodsList();
		},
		handleGoodsShow() {
			this.dialogVisibleGoods = true;
		},
		handleCloseGoods() {
			this.dialogVisibleGoods = false;
		},
		handleClickGoods(tab, event) {
			this.pageCurr = 1;
			this.getGoodsList();
		},
		getRowKey(row) {
			return row.goods_id
		},
		onSelectable(row, index) {
			if (this.couponInfo.goods_ids && this.couponInfo.goods_ids.includes(row.goods_id)) {
				return false;
			} else {
				return true;
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		handleToggleSelecting() {
			let ids = [];
			this.multipleSelection.forEach(item => {
				ids.push(item.goods_id);
			})
			this.checkGoods = JSON.parse(JSON.stringify(this.multipleSelection));
			this.couponForm.goods_ids = this.couponInfo.goods_ids.concat(ids);
			this.dialogVisibleGoods = false;
		},
		handleSave() {
			let loading = this.$loading();
			this.$refs.couponForm.validate((valid) => {
				if (valid) {
					const params = {
						...this.couponForm
					}
					params.goods_ids = params.goods_ids.join(',')
					updateCoupon(params).then(() => {
						loading.close();
						this.$message.success(this.$t('seller.successMsg.save'));
						this.$router.push({
							name: 'couponList',
							query: {
								type: this.couponInfo.type
							}
						});
					}).catch(() => {
						loading.close();
					})
				} else {
					loading.close();
					return false;
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.seller-card {
	.w240 {
		width: 240px;
	}
	.ml10 {
		margin-left: 10px;
	}
}

.page-bottom {
	margin-bottom: 20px;
}

.seller-dialog-scrollbar {
	height: 520px;
	.goods-item {
		display: flex;
		.pic {
			width: 60px;
			height: 60px;
			object-fit: cover;
		}

		.item-mian {
			flex: 1;
			width: 0;
			margin-left: 10px;
			line-height: 17px;

			.title {
				height: 34px;
				font-size: 14px;
				color: #666666;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			.number {
				height: 17px;
				margin-top: 9px;
				color: #333333;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
</style>
